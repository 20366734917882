import * as actions from './actions'


const soporteModule = {
    namespaced: true,
    actions,
}


export default soporteModule
