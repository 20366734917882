import * as actions from './actions'


const satisfaccionModule = {
    namespaced: true,
    actions,
}


export default satisfaccionModule
